import { useStore } from "vuex";
import { computed, onBeforeMount, ref } from "vue";
import useSharedUI from "../../../../shared/composables/useSharedUI";
const useRewards = () => {
  const store = useStore();
  const isCopying = ref(false);
  const isLoadingVideo = ref(false)
  const { sendNotificationReward } = useSharedUI()
  const linkVideo = ref("");
  let userAuthenticated = computed(
    () => store.getters["shared/getUserAuthentication"]
  );
  userAuthenticated = userAuthenticated.value;

  onBeforeMount(async () => {
    await store.dispatch("students/getRewardsTypesDiscounts");
    await store.dispatch("students/dashboardStart");
  });

  const sendVideo = async () => {
    isLoadingVideo.value = true;
    const  {success } = await store.dispatch("students/sendVideo",  { link:linkVideo.value});
    if(success)
      sendNotificationReward()
    isLoadingVideo.value = false;
  }

  const studentInformation = computed(
    () => store.getters["students/studentInformation"]
  );
  const isLoading = computed(() => store.getters["students/rewardsIsLoading"]);
  const typesDiscounts = computed(
    () => store.getters["students/rewardsTypesDiscounts"]
  );

  const discountCode = computed(
    () => store.getters["students/rewardsDiscountCode"]
  );

  const copyCode = () => {
    isCopying.value = true;

    const studentName = studentInformation.value.name ?? "Usuario";

    const code = discountCode.value;
    const text = `Thanks to ${studentName}'s reference code you will receive a 15% off, at the time of payment enter the code ${code}`;

    window.navigator.clipboard.writeText(text);

    setTimeout(() => {
      isCopying.value = false;
    }, 2000);
  };

  const isTermsOpen = computed(() => store.getters["students/toggleTerms"]);

  const toggleTerms = (isOpen) => {
    store.commit("students/toggleTerms", isOpen);
  };

  const userInformation = computed(() => {
    return store.getters["students/studentInformation"];
  })
  

  return {
    typesDiscounts,
    userInformation,
    discountCode,
    isLoading,
    isCopying,
    copyCode,
    toggleTerms,
    isTermsOpen,
    isLoadingVideo,
    linkVideo,
    sendVideo,
  };
};

export default useRewards;
