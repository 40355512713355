<template>
  <div
    class="p-3 border-r-10 pb-5 col-md-6"
    :class="show_code_user ? 'bg-secondary-3' : 'bg-accent-8'"
  >
    <header
      class="bg-white p-3 border-r-10 text-center mb-4"
      :class="show_code_user ? 'border-secondary-0' : 'border-accent-5'"
    >
      <h1
        class="fw-bold fs-4 mb-1"
        :class="show_code_user ? 'text-secondary-1' : 'text-accent-6'"
      >
        {{ title }}
      </h1>
      <h2 class="text-neutro-1 fs-4 fw-bold mb-0">{{ subtitle }}</h2>
    </header>
    <div class="mb-4 discount-code" v-if="show_code_user && discountCode">
      <p
        class="bg-secondary-2 p-2 text-center border-r-10 text-neutro-1 w-100 text-break"
      >
        {{ discountCode }}
      </p>
      <button
        class="btn text-white bg-secondary-1 bg-hover-secondary-1 bl-shadow p-2 px-2 w-100 fw-bold d-flex align-items-center gap-2 justify-content-center"
        @click="copyCode"
      >
        <i class="pi fs-4" :class="isCopying ? 'pi-check' : 'pi-copy'"></i>
        <span class="fs-6"> {{ isCopying ? "Copied" : "Copy" }} </span>
      </button>
    </div>

    <p class="text-neutro-1 mb-0 lh-sm" v-html="description" style="white-space: pre-line"></p>

    <div v-if="!show_code_user" class="mt-4 d-flex flex-column flex-ld-row gap-2 align-items-center">
      <input-text
        type="url"
        v-model="linkVideo"
        placeholder="Copy here your YouTube video’s link"
        class="input-small py-2 border-0 px-3"
      />
      <button
        :disabled="isLoadingVideo"
        @click="sendVideo"
        class="btn bg-accent-6 bg-hover-accent-6 bl-shadow text-white px-5 py-2 w-100 w-lg-auto"
      >
        Send
      </button>
    </div>
  </div>
</template>

<script>
import useRewards from "@/modules/students/composables/Rewards/useRewards";

export default {
  name: "TypeDiscountComponent",
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    show_code_user: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      discountCode,
      isCopying,
      copyCode,
      isLoadingVideo,
      sendVideo,
      linkVideo,
    } = useRewards();

    return {
      discountCode,
      isCopying,
      copyCode,
      isLoadingVideo,
      sendVideo,
      linkVideo,
    };
  },
};
</script>

<style lang="scss" scoped>
.discount-code {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 8rem;
  align-items: center;
  max-width: 350px;
  margin: auto;
  @media screen and (max-width: 992px) {
    grid-template-columns: 1fr;
    max-width: none;
    margin: 0;
  }
}
</style>
