<template>
  <banner-component />
  <types-discounts-component />
</template>

<script>
import BannerComponent from "@/modules/students/components/Rewards/BannerComponent";
import TypesDiscountsComponent from "@/modules/students/components/Rewards/TypesDiscountsComponent";

export default {
  name: "RewardsView",
  components: {
    BannerComponent,
    TypesDiscountsComponent,
  },
};
</script>