<template>
  <div class="reward-banner p-4 border-r-10">
    <div class="mb-5">
      <h1 class="text-accent-6 bl-text-shadow fs-1 fw-bold mb-3">
        Supercharge your bright experience
      </h1>
      <p class="bl-text-shadow fs-5 lh-sm">
        You can earn benefits on your Bright Path and keep learning
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.reward-banner {
  
  background-image: url("/assets/modules/students/rewards-bg-banner.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: grid;
  grid-template-columns: 25rem 1fr;
  font-weight: bold;
  color:#00246d;
  gap: 1rem;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    
    background-image: url("/assets/modules/students/card_supercharge.png");
    height: 347px;
    & h1{
      font-size: 1.5rem!important;
    }
    & p{
      font-weight: lighter;
      color:white;
      font-size: 1rem!important;
    }
  }
}
</style>