<template>
  <div class="bg-white border-r-10 p-3 mt-4">
    <h1 class="text-primary-0 fs-3 fw-bold mb-3">
      You can earn a discount on your next month, if you:
    </h1>
    <div class="d-flex flex-column flex-md-row gap-3">
      <template v-if="isLoading">
        <Skeleton
          width="100%"
          height="16rem"
          v-for="i in [1, 2]"
          :key="i"
          class="border-r-10"
        />
      </template>
      <template v-else>
        <type-discount-component
          v-for="(card, index) in typesDiscounts"
          :key="index"
          :title="card.title"
          :subtitle="card.subtitle"
          :description="card.description"
          :show_code_user="card.show_code_user"
        />
      </template>
    </div>
    <div class="d-flex align-items-center justify-content-between mt-4">
      <button
        class="btn text-primary-1 text-hover-primary-1 p-0 m-0"
        @click="toggleTerms(true)"
      >
        Read Terms & Conditions
      </button>
      <!-- <div class="d-flex align-items-center gap-3">
        <p class="text-secondary-1 fw-normal">
          Have another idea? Share your idea with us!
        </p>
        <a
          :href="`mailto:renzo@uniclick.com.pe?Subject=Interesado en una nueva idea de descuento&cc=${userInformation.email}`"
          class="
            btn
            text-secondary-1
            border-secondary-1
            bg-hover-light-secondary-1
            p-2
            px-4
            d-flex
            align-items-center
            gap-2
          "
        >
          <span class="material-icons"> mail </span>
          Contact us
        </a>
      </div> -->
    </div>
  </div>
</template>

<script>
import TypeDiscountComponent from "@/modules/students/components/Rewards/TypeDiscountComponent";
import useRewards from "@/modules/students/composables/Rewards/useRewards";
import { onUpdated } from "vue";

export default {
  name: "TypesDiscountsComponent",
  components: {
    TypeDiscountComponent,
  },
  setup() {
    const { isLoading, typesDiscounts, toggleTerms, userInformation } =
      useRewards();

      
    onUpdated(() => {
      console.log(typesDiscounts.value)
    })

    return {
      isLoading,
      userInformation,
      typesDiscounts,
      toggleTerms,
    };
  },
};
</script>